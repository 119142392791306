//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.rdt_TableCell div {
	white-space: normal !important;
	overflow: visible !important;
}

.list-group-flush.list-group-horizontal > .list-group-item {
	border-bottom-width: 1px;
	border-top-width: 0;
}

.list-group-flush.list-group-horizontal:last-child > .list-group-item {
	border-bottom-width: 0;
}

.list-group-flush.list-group-horizontal.items-2 > .list-group-item {
	min-width: 50%;
}